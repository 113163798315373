import React, { useState } from "react";
import { directors } from "./OurPeopleData";
import Container from "@material-ui/core/Container";
import { Modal } from "./Modal";
import "./About.css";

const Director = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedDirector, setSelectedDirector] = React.useState(null);

  const openModal = () => {
    setShowModal((prev) => !prev);
  };

  return (
    <div>
      <Container>
        <section className="ourpeoplestakeholdercontainer">
          {/* {directors.map((director) => {
            const { name, img, id,title} = director;
            return (
              <>
                <article
                  key={id}
                  id={`${id}`}
                  onClick={openModal}
                  className="article-width flex-article-ourpeople"
                >
                  <div>{img}</div>
                  <div>{name}</div>
                  <div>{title}</div>
                </article>
                <Modal
                  showModal={showModal}
                  setShowModal={setShowModal}
                  director={director}
                />
              </>
            );
          })} */}
          {directors.map((director) => {
            const { name, img, id, title } = director;
            return (
              <>
                <article
                  key={id}
                  id={`${id}`}
                  onClick={() => {
                    setSelectedDirector(director);
                    setShowModal(true);
                  }}
                  className="article-width flex-article-ourpeople"
                >
                  <div>{img}</div>
                  <div>{name}</div>
                  <div>{title}</div>
                </article>
                <Modal
                  showModal={showModal}
                  setShowModal={setShowModal}
                  director={selectedDirector}
                />
              </>
            );
          })}
        </section>
      </Container>
    </div>
  );
};

export default Director;

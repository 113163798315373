import React from "react";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { Images } from "../shared/themes";
import Container from "@material-ui/core/Container";
import InstagramIcon from "@material-ui/icons/Instagram";
import FacebookIcon from "@material-ui/icons/Facebook";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import TwitterIcon from "@material-ui/icons/Twitter";
import PhoneIcon from "@material-ui/icons/Phone";
import MailIcon from "@material-ui/icons/Mail";
import RoomIcon from "@material-ui/icons/Room";

const useStyles = makeStyles((theme) => ({
  containerStyles: {
    display: "flex",
    justifyContent: "space-between",
    paddingTop: 35,
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      textAlign: "center",
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      textAlign: "center",
    },
  },
  footerHeader: {
    color: "#FFFFFF",
    fontSize: "17px",
    paddingTop: "50px",
    paddingBottom: "20px",
    textTransform: "uppercase",
    fontFamily: "Calibri",
    fontWeight: 400,
    lineHeight: "28px",
    letterSpacing: "2px",
  },
  footerText: {
    paddingTop: "12px",
    fontSize: "16px",
    fontFamily: "Calibri",
    lineHeight: "28px",
    color: "#FFFFFF",
    [theme.breakpoints.down("md")]: {
      paddingTop: "0",
      fontSize: "12px",
    },
    [theme.breakpoints.down("xs")]: {
      paddingTop: "0",
      fontSize: "14px",
    },
  },
  footerText1: {
    paddingTop: "12px",
    fontSize: "16px",
    fontFamily: "Calibri",
    lineHeight: "28px",
    color: "#FFFFFF",
    [theme.breakpoints.down("md")]: {
      paddingTop: "0",
      fontSize: "12px",
    },
    [theme.breakpoints.down("xs")]: {
      paddingTop: "0",
      fontSize: "14px",
    },
  },
  footerText2: {
    paddingTop: "12px",
    fontSize: "15px",
    fontFamily: "Calibri",
    lineHeight: "28px",
    color: "#FFFFFF",
    wordBreak: "none",
    [theme.breakpoints.down("md")]: {
      paddingTop: "0",
      fontSize: "12px",
    },
    [theme.breakpoints.down("xs")]: {
      paddingTop: "0",
      fontSize: "14px",
    },
  },
  logoStyles: {
    maxWidth: "250px",
    paddingTop: "30px",
    [theme.breakpoints.down("xs")]: {
      maxWidth: "120px",
      paddingLeft: "4px",
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "180px",
      paddingLeft: "14px",
    },
  },
  socialIcons: {
    color: "white",
    paddingTop: "50px",
  },
  contactIcons: {
    paddingRight: "12px",
    fontSize: "32px",
  },
  imgfooter: {
    marginRight: "60px",
    [theme.breakpoints.down("sm")]: {
      marginRight: "0",
    },
  },
}));

const Footer = () => {
  const classes = useStyles();

  return (
    <>
      <div
        style={{
          background: "#131747",
          height: 574,
          paddingBottom: "30px",
        }}
      >
        <Grid container justify="space-between" alignItems="center">
          <Container className={classes.containerStyles}>
            <div style={{ display: "flex" }}>
              <Grid
                item
                md={3}
                xs={12}
                className={classes.imgfooter}
                style={{ marginRight: 55, width: 270 }}
              >
                <img
                  src={Images.completeLogo}
                  alt="Logo"
                  className={classes.logoStyles}
                />
                <Box className={classes.footerText}>
                  We're leveraging technology to build the best solutions to
                  your financial problem
                </Box>
                <div className={classes.socialIcons}>
                  <InstagramIcon />
                  <FacebookIcon />
                  <LinkedInIcon />
                  <TwitterIcon />
                </div>
              </Grid>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-evenly",
                }}
              >
                <Grid
                  item
                  md={3}
                  xs={12}
                  className={classes.textgrid}
                  style={{ whiteSpace: "nowrap" }}
                >
                  <Box className={classes.footerHeader}>SUBSIDIARIES</Box>
                  {/* <Box className={classes.footerText1}>Sebastian BDC</Box> */}
                  <Box className={classes.footerText1}>
                    Swift&Allied Partners
                  </Box>
                  <Box
                    // style={{ whiteSpace: "nowrap" }}
                    className={classes.footerText1}
                  >
                    Assets Microfinance Bank
                  </Box>
                  <Box className={classes.footerText1}>Canary Finance</Box>

                  {/* <Box className={classes.footerText1}>Nairagram</Box> */}
                  {/* <Box className={classes.footerText1}>Instant Cash</Box> */}
                  {/* <Box className={classes.footerText1}>MonieTree</Box> */}
                </Grid>
                <Grid item md={2} xs={12} className={classes.textgrid1}>
                  <Box className={classes.footerHeader}>MEDIA</Box>
                  <Box className={classes.footerText1}>Blog</Box>
                  <Box className={classes.footerText1}>News</Box>
                  <Box className={classes.footerText1}>Events</Box>
                  <Box className={classes.footerText1}>Gallery</Box>
                </Grid>
                <Grid item md={2} xs={12} className={classes.textgrid2}>
                  <Box className={classes.footerHeader}>Company</Box>
                  <Box className={classes.footerText1}>About</Box>
                  <Box className={classes.footerText1}>Terms</Box>
                  <Box className={classes.footerText1}>Privacy Policy</Box>
                  {/* <Box className={classes.footerText1}>Careers</Box> */}
                </Grid>
                <Grid item md={2} xs={12} className={classes.textgrid3}>
                  <Box
                    style={{ whiteSpace: "nowrap" }}
                    className={classes.footerHeader}
                  >
                    CONTACT US
                  </Box>
                  {/* <Box
                    style={{ whiteSpace: "nowrap" }}
                    className={classes.footerText1}
                  >
                    <PhoneIcon className={classes.contactIcons} />
                    08145515280, 01-6329480
                  </Box> */}
                  <Box
                    style={{ whiteSpace: "nowrap" }}
                    className={classes.footerText1}
                  >
                    <MailIcon className={classes.contactIcons} />
                    info@canarypointcs.com
                  </Box>
                  <Box
                    style={{ display: "flex" }}
                    className={classes.footerText1}
                  >
                    <RoomIcon className={classes.contactIcons} />
                    {/* <p>
                      17a Dele Adedeji Street, Off Bisola Durosinmi-Etti Street,
                      Lekki Phase I -Lagos. Nigeria
                    </p> */}
                    <p>
                      The Perez, 3 Perez Drive, Lekki Phase I -Lagos. Nigeria
                    </p>
                  </Box>
                </Grid>
              </div>
            </div>
          </Container>
        </Grid>
        <Box
          style={{ textAlign: "center", paddingTop: "50px" }}
          className={classes.footerText2}
        >
          Copyright © 2021 Canary Point Holdings
        </Box>
      </div>
    </>
  );
};

export default Footer;

import React, { useState } from "react";
import { people } from "./OurPeopleData";
import Container from "@material-ui/core/Container";
import { Modal } from "./Modal";
import "./About.css";

const PlaceholderAvatar = () => (
  <svg
    width="233"
    height="233"
    viewBox="0 0 233 233"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ width: "80%", marginBottom: "0" }}
  >
    <circle cx="116.5" cy="116.5" r="116.5" fill="#BDEAFF" />
    <mask
      id="mask0"
      mask-type="alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="233"
      height="233"
    >
      <circle cx="116.5" cy="116.5" r="116.5" fill="white" />
    </mask>
    <g mask="url(#mask0)">
      <circle cx="117" cy="92" r="34" fill="#74D7F8" />
      <circle cx="117" cy="199" r="67" fill="#74D7F8" />
    </g>
  </svg>
);

const Management = () => {
  const [showModal, setShowModal] = useState(false);

  const openModal = () => {
    setShowModal((prev) => !prev);
  };
  return (
    <div>
      <Container>
        <section className="ourpeoplestakeholdercontainer">
          {people.map((person) => {
            const { name, img, id, title } = person;
            return (
              <article
                key={id}
                id={`${id}`}
                onClick={openModal}
                className="article-width flex-article-ourpeople"
              >
                {img ? (
                  <img
                    src={img}
                    alt="management-team"
                    className="our-people-img"
                  />
                ) : (
                  <PlaceholderAvatar />
                )}
                <p className="name-styles-stakeholders">{name}</p>
                <p className="text-center-our-people">{title}</p>
              </article>
            );
          })}
          {/* <Modal showModal={showModal} setShowModal={setShowModal} /> */}
        </section>
      </Container>
    </div>
  );
};

export default Management;

import GothamRoundedBold from "assets/font/GothamRounded-Bold.otf";
import GothamRounded2 from "assets/font/GothamRounded-Medium.otf";
import Calibri from "assets/font/calibri-regular.ttf";

const subsidiariesStyle = (theme) => ({
  root: {
    flexGrow: 1,
    color: "#6E6E6E",
    lineHeight: 1.2,
    letterSpacing: 1,
  },
  typographyH5: {
    fontSize: "36px",
    fontWeight: 400,
    color: "#2D3690",
    lineHeight: 1.5,
    fontFamily: "Gotham Rounded Medium",
    src: `
      url(${GothamRoundedBold}) format('otf')
    `,
    [theme.breakpoints.down("sm")]: {
      fontSize: "26px",
      fontWeight: 400,
    },
  },
  typographybody1: {
    fontSize: "20px",
    fontWeight: 400,
    color: "#6E6E6E",
    lineHeight: "29px",
    letterSpacing: "o.33px",
    fontFamily: "Calibri",
    src: `
      url(${Calibri}) format('ttf')
    `,
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
    },
  },
  typographybody2: {
    padding: "2rem",
    fontSize: "20px",
    fontWeight: 400,
    color: "#6E6E6E",
    lineHeight: "29px",
    letterSpacing: "o.33px",
    fontFamily: "Calibri",
    src: `
      url(${Calibri}) format('ttf')
    `,
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
    },
  },
  SubsidWrapper: {
    margin: "0 auto",
  },
  SubsidiariesHeader: {
    display: "flex",
    flexDirection: "row",
    margin: "1rem auto",
    justifyContent: "center",
    alignItems: "center",
  },
  CanaryLogo: {
    width: "170px",
    padding: "1rem",
  },
  SubsidiariesHeadGrid: {
    padding: "1rem",
  },
  SubsidLogoWrapper: {
    margin: "0 auto",
  },
  subLogosDiv: {
    display: "flex",
    flexDirection: "row",
    margin: "1rem auto",
    justifyContent: "center",
    alignItems: "center",
  },
  subLogoGrid: {
    margin: "2rem 2rem",
  },
  subLogo: {
    padding: "1rem",
    height: "120px",
    width: "120px",
    borderRadius: "50%",
    cursor: "pointer",
    boxShadow: "0px 2px 13px 6px rgba(224, 108, 38, 0.309709);",

    "&:hover": {
      boxShadow: "0px 2px 13px 6px rgba(224, 108, 38, 0.309709);",
      background: "0px 2px 13px 6px rgba(224, 108, 38, 0.309709);",
    },
  },
  subLogo2: {
    padding: "1rem",
    height: "120px",
    width: "120px",
    borderRadius: "50%",
    cursor: "pointer",
    boxShadow:
      "0 5px 20px rgba(154,160,185,.20), 0 15px 40px rgba(166,173,201,.2);",
    "&:hover": {
      boxShadow: "0px 2px 13px 6px rgba(224, 108, 38, 0.309709);",
      background: "0px 2px 13px 6px rgba(224, 108, 38, 0.309709);",
    },
  },
  SebastianHeadGrid: {
    backgroundColor: "#4F88C7",
    borderRadius: "9px",
    margin: "2rem auto",
    width: "100%",
  },
  SebastianTypoText: {
    color: "#FFFFFF",
    fontSize: "28px",
    lineHeight: 1.5,
    letterSpacing: 1,
    fontWeight: 400,
    padding: theme.spacing(6),
    width: "100%",
    textAlign: "center",
    fontFamily: "Gotham Rounded Medium",
    src: `
      url(${GothamRounded2}) format('otf')
    `,
  },
  SubsidWebDetails: {
    margin: "2rem auto",
  },
  SubsidDetailsGrid: {
    margin: "0 auto",
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  SubsidLogoContent: {
    padding: "1rem",
    width: "50%",
    margin: "0 auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  SubsidLogoContent2: {
    padding: "1rem",
    width: "50%",
    margin: "0 auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },

  SubsidLogoIbdcDiv: {
    width: "70%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  SubsidLogoIbdc: {
    width: "100%",
    cursor: "pointer",
  },
  buttonGrid: {
    margin: "2rem auto",
  },
  button: {
    fontSize: "1rem",
    padding: "10px 25px",
    borderRadius: "30px",
    border: "none",
    color: "#ffffff",
    cursor: "pointer",
    backgroundColor:
      "linear-gradient(183.92deg, #F2A54E -38.53%, #E06C26 156.47%)",
    background: "linear-gradient(183.92deg, #F2A54E -38.53%, #E06C26 156.47%)",
    "&:hover": {
      background: "#FB7518",
      color: "#ffffff",
    },
  },
  IbdcContent: {
    padding: "1rem",
  },
  formContainer: {
    margin: "4rem auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #E7E7E7",
    borderRadius: "9px",
  },
  signUpGrid: {
    margin: "3rem auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  signUpTypo: {
    fontSize: "25px",
    fontWeight: "400px",
    lineHeight: 1.2,
    letterSpacing: "0.6px",
    textAlign: "center",
    padding: "1rem 2rem",
    fontFamily: "Calibri",
    src: `
      url(${Calibri}) format('ttf')
    `,
  },
  formdiv: {
    margin: "1rem auto",
  },
  form: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
  },
  input: {
    padding: "16px 10px",
    border: "1px solid #979797",
    borderRadius: "9px 0 0 9px",
    fontSize: "18px",
    fontWeight: 400,
    color: "#9C9C9C",
    flex: 1,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  singUpBtn: {
    fontSize: "18px",
    borderRadius: "0 9px 9px 0",
    border: "none",
    padding: "12px 28px",
    color: "white",
    background: "linear-gradient(180deg, #43DDF7 0%, #20B8EB 100%)",
  },
  companyLogoLink: {
    cursor: "pointer",
  },
  active: {
    borderBottom: "4px solid red",
    paddingTop: "2rem",
  },
});

export default subsidiariesStyle;

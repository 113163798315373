import IbcdLogo from "assets/img/whatwedo-img/IbdcLogo.png";
import swiftAlliedLogo from "assets/img/whatwedo-img/swiftAlliedLogo.png";
// import MonieSTree from "../../assets/images/MonieSTree.png";
import assetsMicrofinanceLogo from "assets/img/whatwedo-img/Assets-Microfinance-Bank.png";
import MonieSTree from "assets/images/canary-capital.png";
import nairagramLogo from "assets/images/canary-finance.png";
import instantCashLogo from "assets/img/whatwedo-img/instantCash.gif";
// import nairagramLogo from "assets/img/whatwedo-img/companyLogo4.png";

import { makeStyles } from "@material-ui/core/styles";
import { Button, Grid, Typography } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import style from "./SubsidiariesStyle";

const useStyles = makeStyles(style);

const Content1 = () => {
  const classes = useStyles();

  return (
    <div>
      <Container>
        <Grid container>
          <Grid
            item
            xs={10}
            sm={8}
            md={8}
            className={classes.SebastianHeadGrid}
          >
            <Typography variant="h5" className={classes.SebastianTypoText}>
              Sebastian BDC Ltd
            </Typography>
          </Grid>
        </Grid>
        <Grid container className={classes.SubsidWebDetails}>
          <Grid item xs={12} md={9} className={classes.SubsidDetailsGrid}>
            <Grid item className={classes.SubsidLogoContent}>
              <Grid className={classes.SubsidLogoIbdcDiv}>
                <a href="/#">
                  <img
                    src={IbcdLogo}
                    alt="IBDC logo"
                    className={classes.SubsidLogoIbdc}
                  />
                </a>
              </Grid>
              <Grid className={classes.buttonGrid}>
                <Button className={classes.button}>Visit Website</Button>
              </Grid>
            </Grid>
            <Grid item xs={12} md={10} className={classes.IbdcContent}>
              <Typography className={classes.typographybody2}>
                Sebastian BDC Ltd. Is a member of the CPCSL group of companies.
                Sebastian is a CBN licensed Bureau De Change company, whose
                services include but not limited to the buying and selling of
                foreign currency such as US Dollars, British Pounds and Euros.
                Sebastian BDC offers several competitive advantages in the
                market that makes it superior and unique from other BDCs in the
                Forex market. Their service extends to delivery services at a
                minimal fee for customers that purchase large amounts of
                currency , deposit of forex bought directly into the customer’s
                domiciliary account or the Naira equivalent into their Naira
                accounts.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};
const Content2 = () => {
  const classes = useStyles();

  return (
    <div>
      <Container>
        <Grid container>
          <Grid
            item
            xs={10}
            sm={8}
            md={8}
            className={classes.SebastianHeadGrid}
          >
            <Typography variant="h5" className={classes.SebastianTypoText}>
              Swift & Allied Partners
            </Typography>
          </Grid>
        </Grid>
        <Grid container className={classes.SubsidWebDetails}>
          <Grid item xs={12} md={9} className={classes.SubsidDetailsGrid}>
            <Grid item className={classes.SubsidLogoContent}>
              <Grid className={classes.SubsidLogoIbdcDiv}>
                <a href="/#">
                  <img
                    src={swiftAlliedLogo}
                    alt="swift-Allied logo"
                    className={classes.SubsidLogoIbdc}
                  />
                </a>
              </Grid>
              <Grid className={classes.buttonGrid}>
                <Button className={classes.button}>Visit Website</Button>
              </Grid>
            </Grid>
            <Grid item xs={12} md={10} className={classes.IbdcContent}>
              <Typography className={classes.typographybody2}>
                is a company within the Canary Point Holdings. It was set up to
                boost credit facilities to businesses and individuals who are
                not able to access the traditional banking facilities. It is
                fully licensed under the Lagos State Lenders Licence .Our Loan
                amounts range from 1 Million Naira to 50 million Naira. These
                loans are however fully collaterised along with a guarantee. The
                larger transaction amounts typically require an in depth
                explanation of the source of payback.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};
const Content3 = () => {
  const classes = useStyles();

  return (
    <div>
      <Container>
        <Grid container>
          <Grid
            item
            xs={10}
            sm={8}
            md={8}
            className={classes.SebastianHeadGrid}
          >
            <Typography variant="h5" className={classes.SebastianTypoText}>
              Assets Microfinance Bank Ltd. (AMFB)
            </Typography>
          </Grid>
        </Grid>
        <Grid container className={classes.SubsidWebDetails}>
          <Grid item xs={12} md={9} className={classes.SubsidDetailsGrid}>
            <Grid item className={classes.SubsidLogoContent2}>
              <Grid className={classes.SubsidLogoIbdcDiv}>
                <a href="/#">
                  <img
                    src={assetsMicrofinanceLogo}
                    alt="assets-Microfinance-Logo"
                    className={classes.SubsidLogoIbdc}
                  />
                </a>
              </Grid>
              <Grid className={classes.buttonGrid}>
                <Button className={classes.button}>Visit Website</Button>
              </Grid>
            </Grid>
            <Grid item xs={12} md={10} className={classes.IbdcContent}>
              <Typography className={classes.typographybody2}>
                is the CBN licenced subisidary of Canary Point Holdings fully
                licenced to offer microfinance services such as savings, loans,
                and other financial services to the MSME enterprises. At AMFB we
                are focused on providing fast and need oriented Micro Finance
                Banking and Investment Services to suitable and qualified
                Individuals and Corporate organizations. Our service extends to
                providing Salary Loans and Business Loans from N50,000 up to a
                maximum of 1 Million Naira.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

const Content4 = () => {
  const classes = useStyles();

  return (
    <div>
      <Container>
        <Grid width="100%" container>
          <Grid
            item
            xs={10}
            sm={8}
            md={8}
            className={classes.SebastianHeadGrid}
          >
            <Typography variant="h5" className={classes.SebastianTypoText}>
              Canary Point Finance Limited
            </Typography>
          </Grid>
        </Grid>
        <Grid container className={classes.SubsidWebDetails} width="100%">
          <Grid item xs={12} md={9} className={classes.SubsidDetailsGrid}>
            <Grid item className={classes.SubsidLogoContent2}>
              <Grid className={classes.SubsidLogoIbdcDiv2}>
                <a href="/#">
                  <img
                    src={nairagramLogo}
                    width="100%"
                    alt="nairagram logo"
                    className={classes.SubsidLogoIbdc}
                  />
                </a>
              </Grid>
              <Grid className={classes.buttonGrid}>
                <Button className={classes.button}>Visit Website</Button>
              </Grid>
            </Grid>
            <Grid item xs={12} md={10} className={classes.IbdcContent}>
              <Typography className={classes.typographybody2}>
                Canary Point Finance Limited is a Central Bank of Nigeria
                licensed Finance House offering financial solutions that assist
                clients in achieving their objectives. As a Finance Company, we
                complement banks in bridging financing and investing gaps. Our
                Finance House is led by experienced professionals with decades
                of industry experience in local and international financial
                transactions. At Canary Point Finance, we leverage our deep
                understanding of the Nigerian financial markets, macro-economic
                trends and customer expectations to proffer bespoke workable
                solutions to clients’ funding requirements, provide structured
                investable products and deliver best-in-class financial
                intermediation services.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

const Content5 = () => {
  const classes = useStyles();

  return (
    <div>
      <Container>
        <Grid container>
          <Grid
            item
            xs={10}
            sm={8}
            md={8}
            className={classes.SebastianHeadGrid}
          >
            <Typography variant="h5" className={classes.SebastianTypoText}>
              Instant Cash
            </Typography>
          </Grid>
        </Grid>
        <Grid container className={classes.SubsidWebDetails}>
          <Grid item xs={12} md={9} className={classes.SubsidDetailsGrid}>
            <Grid item className={classes.SubsidLogoContent}>
              <Grid className={classes.SubsidLogoIbdcDiv}>
                <a href="/#">
                  <img
                    src={instantCashLogo}
                    alt="instant Cash logo"
                    className={classes.SubsidLogoIbdc}
                  />
                </a>
              </Grid>
              <Grid className={classes.buttonGrid}>
                <Button className={classes.button}>Visit Website</Button>
              </Grid>
            </Grid>
            <Grid item xs={12} md={10} className={classes.IbdcContent}>
              <Typography className={classes.typographybody2}>
                It is essentially a mobile application that allows customers to
                borrow money on the go.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};
const Content6 = () => {
  const classes = useStyles();

  return (
    <div>
      <Container>
        <Grid container>
          <Grid
            item
            xs={10}
            sm={8}
            md={8}
            className={classes.SebastianHeadGrid}
          >
            <Typography variant="h5" className={classes.SebastianTypoText}>
              Canary Point Capital
            </Typography>
          </Grid>
        </Grid>
        <Grid container className={classes.SubsidWebDetails}>
          <Grid item xs={12} md={9} className={classes.SubsidDetailsGrid}>
            <Grid item className={classes.SubsidLogoContent}>
              <Grid className={classes.SubsidLogoIbdcDiv}>
                <a href="/#">
                  <img
                    src={MonieSTree}
                    alt="monieTree logo"
                    className={classes.SubsidLogoIbdc}
                  />
                </a>
              </Grid>
              <Grid className={classes.buttonGrid}>
                <Button className={classes.button}>Visit Website</Button>
              </Grid>
            </Grid>
            <Grid item xs={12} md={10} className={classes.IbdcContent}>
              <Typography className={classes.typographybody2}>
                Canary Point Capital is an SEC licensed investment firm with a
                strategic approach to capital allocation and portfolio
                management. The firm focuses on identifying high-growth
                potential opportunities across various sectors, with a strong
                emphasis on financial services, technology and innovative
                industries. Canary Point Capital leverages its expertise and
                extensive network to drive value creation and sustainable growth
                for its portfolio companies.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export { Content1, Content2, Content3, Content4, Content5, Content6 };
